import React, { useState } from "react";

import "@fontsource/chilanka";

import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";

import Accordion from "~/components/accordion";
import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={5}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 5</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>Sometimes you have to sort through a lot of information to separate the helpful and relevant information from the rest. Take another look at the lemonade diet cleanse website.</p>

              <p>Can you identify specific words, phrases, or statements that raise questions about the quality of the information? Are there phrases or statements that do not seem to support the claims made?</p>

              <p>Draw a table in your science notebook like the one below to record your ideas. An example is included to get you started.</p>

              <div className="bg-white p-4 rounded shadow">
                <table className="my-5" style={{borderStyle: 'none'}}>
                  <thead>
                    <tr>
                      <th className="pb-4 border-0 border-b border-r border-gray-800 text-left">
                        <strong className="text-xl" style={{ fontFamily: "Chilanka" }}>Idea, phrase, or statement in ad</strong>
                      </th>
                      <th className="pl-4 pb-4 border-0 border-b border-gray-800 text-left">
                        <strong className="text-xl" style={{ fontFamily: "Chilanka" }}>Question you want answered about that statement</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ height: "300px" }}>
                      <td
                        className="pt-4 text-xl text-left border-0 border-r border-gray-800"
                        style={{
                          fontFamily: "Chilanka",
                          verticalAlign: "top"
                        }}
                      >
                        Heal your cells
                      </td>
                      <td
                        className="pt-4 pl-4 text-xl text-left border-0"
                        style={{
                          fontFamily: "Chilanka",
                          verticalAlign: "top"
                        }}
                      >
                        What does it mean to heal cells, and how would that work?
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p>When you are done, your teacher will lead a class discussion so you can share some of your ideas with the class.</p>

              <Button
                action="secondary"
                color="purple"
                href="https://www.nytimes.com/2016/04/21/health/juice-cleanse-toxin-misconception.html?_r=0"
                icon="external"
                title="Lemonade diet cleanse website"
              >
                Lemonade diet cleanse website
              </Button>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Students practice their critical thinking skills by analyzing information in the lemonade diet cleanse website. After students have time to complete their analysis, hold a class discussion so students can share their ideas and thinking.</p>

              <p>Click on the link below to see examples of items from the website and questions that might be asked for clarification.</p>

              <Button
                action="secondary"
                className="mt-6 mb-4"
                color="purple"
                href="/files/hs/lesson_3/Sample_Answers_lemonade_cleanse-scl.final.awv2.pdf"
                icon="download"
                title="Example analysis of lemonade diet cleanse site"
              >
                Example analysis of lemonade diet cleanse site
              </Button>

              <Accordion
                className="mt-6 mb-4"
                headerText="Note To Teachers"
                open={true}
              >
                <p>Like other websites used in this module, this site is fake for purposes of the lesson. It does represent a compilation of information from various real websites on the topic.</p>
              </Accordion>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
